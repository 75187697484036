import Cookie from "js-cookie";
import {storage,isBrowser} from "./tools";
/**
 * @description 从cookie中取用户信息,返回用户对象
 * @returns {{user_id: (*|boolean), login_id: (*|boolean), open_id: (*|boolean), user_role: (*|boolean), cur_role: (*|boolean)}}
 */
export function getUser() {
  if (!isBrowser()) return {};
  let userInfo = storage.get('user_info','session') || {};
  return {
    user_id: userInfo.user_id || '',
    user_name: userInfo.user_name || '',
    mis_doc_id: userInfo.mis_doctor_id || '',
    mis_doctor_id: userInfo.mis_doctor_id || '',
    doctor_id: userInfo.doctor_id || '',
    doctor_name: userInfo.doctor_name || '',
    doctor_user_id: userInfo.user_id || '',
    dept_name: userInfo.dept_name || '',
    clinic_id: userInfo.clinic_id || '',
    clinic_name: userInfo.clinic_name || '',
    shop_no: userInfo.shop_no || '',
    city_id: userInfo.city_id || '',
    city_name: userInfo.city_name || '',

    asst_id: userInfo.asst_id || '',
    // cashiers     : userInfo.cashiers || "",
    // pharmacy_id  : userInfo.pharmacy_id || "",
    // pharmacy_name: userInfo.pharmacy_name || "",
    work_no: userInfo.work_no || '',
    doctor_info: userInfo.doctor_info || [],//提取医生多门店出诊信息

    system_uid: Cookie.get('ali_system_uid') || '', //CAS session标记 //2019年6月19日15:28 为了区分多个系统, 每个系统的标记不同
    srv: Cookie.get('SRV') || '', //服务器环境
    ali: {}, //阿里医院医生详情
  };
}

/**
 * @description 清理当前用户信息
 */
export function cleanUser() {
  let aUserInfo = [
    "cashiers","city_id","city_name","clinic_id","clinic_name","dept_name","doctor_id","doctor_name","doctor_user_id","mis_doctor_id","pharmacy_id","pharmacy_name",
    "system_uid","work_no","doctor_info","doc_system_uid",
    "user_id", "user_name", "login_account","work_no", "clinic_id", "clinic_name", "city_id","city_name",
    "pharmacy_id", "pharmacy_name", "pharmacy_type", "is_central_pharmacy", "pharmacy_state","login_to","check_id","system_uid",
    "role","user_role","user_tel","mis_doc_id","doctor_phone","assistant_id","userid","newRooms","newMessage",
    "patient_id","deal_id","patient_name","patient_age","patient_birth","patient_image","patient_sex","patient_type",
    "reservation_phone","patient_user_id","patient_schedule_id","patient_reservation_sort_id","patient_reservation_deal_id","patient_registration_no",
    "department_name","fee_discount_type","is_urgent","isConSeeDoctor","SRV","XSRF-TOKEN","laravel_session", "work_system_uid"];
  if(typeof document !== 'undefined' && window){
    let domainName = window.location.hostname;
    aUserInfo.forEach(item=>{
      Cookie.remove(item, {domain: domainName});
      Cookie.remove(item, {domain: '.gstzy.cn'});
      Cookie.remove(item, {domain: '.gstyun.cn'});
      Cookie.remove(item);
    });
    storage.removeArr(aUserInfo);
    storage.remove('user_info','session')
  }
}
const User = {
  getUser: getUser,
  cleanUser: cleanUser,
};
export default User;
