const chnNumChar = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
const chnUnitSection = ['', '万', '亿', '万亿', '亿亿'];
const chnUnitChar = ['', '十', '百', '千'];

//判断是不是中文字符
export function isChineseChar(str) {
  let reCh = /[u00-uff]/;
  return !reCh.test(str);
}

/**
 * @desc 链接数组对象里的某个属性,并返回一个数组，如 [{mis_doctor_id:123},{mis_doctor_id:3497}] 返回数组[123, 3497]
 * @param arr
 * @param prop
 * @returns {Array}
 */
export function getArrProp(arr, prop) {
  let result = [];
  if (!arr) return result;
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i][prop]);
  }
  return result;
}

//按位与解析医生标签，返回Boolean值 sign: 十进制; num:移位的位数
export function decodeSign(sign, num) {
  let _sign = sign & Math.pow(2, num);
  return (_sign > 0);
}

//按位或返回医生标签值，返回number
export function encodeSign(num) {
  return 0 | Math.pow(2, num);
}

//按位解析位和,返回array
export function decodeSignList(signSum, map) {
  let result = [];
  Object.keys(map).forEach((item) => {
    if (decodeSign(signSum, item)) {
      result.push(map[item]);
    }
  });
  return result;
}

//价格转换 digit:精确到小数点后多少位,不传精确到元, 传则精确到相关位, 最大4位
export function convertPrice(price, digit = 0) {
  let tarPrice = Number(price);
  if (price < 0) {
    let _price = -price;
    return -(convertPrice(_price, digit));
    //}else if(price<100){
    //  return tarPrice.toFixed(digit)
  } else {
    return (tarPrice / 10000).toFixed(digit);
  }
}

//小数转换成百分比string digit:需要转换的小数
export function convertPercent(digit) {
  return calc.Mul(digit, 100) + '%';
}

//性别转换
export function convertGender(genderCode) {
  let map = {
    '0': '未填写',
    '1': '男',
    '2': '女',
  };
  return map[genderCode] || '';
}

/**
 * @description 本地存储包装器
 * @param type不传默认为 localStorage, 传 session 为 sessionStorage
 */
export const storage = {
  checkWindow() {
    return isBrowser();
    /*if(!isBrowser()){
      // console.warn("[Storage] === Storage can ONLY used in browser.");
      return false;
    }
    return true;*/
  },
  checkSupport(type) {
    let winFlag = this.checkWindow();
    return !!(winFlag && window[type]);
    /*if(winFlag && window[type]){
      return true
    }else{
      // console.warn(`[Storage] === ${type} Storage is NOT supported.`);
      return false
    }*/
  },
  checkType(type) {
    if (type && type === 'session') {
      return 'sessionStorage';
    } else {
      return 'localStorage';
    }
  },
  setObj(obj, type) {
    Object.keys(obj).forEach((item) => {
      this.set(item, obj[item], type);
    });
  },
  set(key, value, type) {
    let target = this.checkType(type);
    if (this.checkSupport(target)) {
      return window[target].setItem(key, JSON.stringify(value));
    } else {
      return false;
    }
  },
  get(key, type) {
    let target = this.checkType(type);
    if (this.checkSupport(target)) {
      if (window[target][key] && window[target][key] !== 'undefined') {
        return JSON.parse(window[target][key]);
      } else {
        return window[target][key];
      }
    }
  },
  removeArr(arr, type) {
    if (Array.isArray(arr) && arr.length) {
      arr.forEach((item) => {
        this.remove(item, type);
      });
    } else {
      console.warn('[Storage] === Params must be an array.');
    }
  },
  remove(key, type) {
    let target = this.checkType(type);
    if (this.checkSupport(target)) {
      if (window[target][key] && window[target][key] !== 'undefined') {
        return window[target].removeItem(key);
      }
    }
  },
  clear(type) {
    let target = this.checkType(type);
    window[target].clear();
  },
};
/**
 * @description js精准计算
 * */
export const calc = {
  /**
   * 函数，加法函数，用来得到精确的加法结果
   * 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
   * 参数：arg1：第一个加数；arg2第二个加数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数）
   * 调用：Calc.Add(arg1,arg2,d)
   * 返回值：两数相加的结果
   * @return {number}
   */
  Add: function(arg1, arg2) {
    arg1 = (arg1).toString();
    arg2 = (arg2).toString();
    let arg1Arr = arg1.split('.'), arg2Arr = arg2.split('.'), d1 = arg1Arr.length === 2 ? arg1Arr[1] : '',
        d2 = arg2Arr.length === 2 ? arg2Arr[1] : '';
    let maxLen = Math.max(d1.length, d2.length);
    let m = Math.pow(10, maxLen);
    let result = Number(((arg1 * m + arg2 * m) / m).toFixed(maxLen));
    let d = arguments[2];
    return (typeof d === 'number') ? Number((result).toFixed(d)) : result;
  },
  /**
   * 函数：减法函数，用来得到精确的减法结果
   * 说明：函数返回较为精确的减法结果。
   * 参数：arg1：第一个加数；arg2第二个加数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数
   * 调用：Calc.Sub(arg1,arg2)
   * 返回值：两数相减的结果
   */
  Sub: function(arg1, arg2) {
    return calc.Add(arg1, -Number(arg2), arguments[2]);
  },
  /**
   * 函数：乘法函数，用来得到精确的乘法结果
   * 说明：函数返回较为精确的乘法结果。
   * 参数：arg1：第一个乘数；arg2第二个乘数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数)
   * 调用：Calc.Mul(arg1,arg2)
   * 返回值：两数相乘的结果
   * @return {number}
   */
  Mul: function(arg1, arg2) {
    let r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
    m = (r1.split('.')[1] ? r1.split('.')[1].length : 0) + (r2.split('.')[1] ? r2.split('.')[1].length : 0);
    resultVal = Number(r1.replace('.', '')) * Number(r2.replace('.', '')) / Math.pow(10, m);
    return typeof d !== 'number' ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
  },
  /**
   * 函数：除法函数，用来得到精确的除法结果
   * 说明：函数返回较为精确的除法结果。
   * 参数：arg1：除数；arg2被除数；d要保留的小数位数（可以不传此参数，如果不传则不处理小数位数)
   * 调用：Calc.Div(arg1,arg2)
   * 返回值：arg1除于arg2的结果
   * @return {number}
   */
  Div: function(arg1, arg2) {
    let r1 = arg1.toString(), r2 = arg2.toString(), m, resultVal, d = arguments[2];
    m = (r2.split('.')[1] ? r2.split('.')[1].length : 0) - (r1.split('.')[1] ? r1.split('.')[1].length : 0);
    resultVal = Number(r1.replace('.', '')) / Number(r2.replace('.', '')) * Math.pow(10, m);
    return typeof d !== 'number' ? Number(resultVal) : Number(resultVal.toFixed(parseInt(d)));
  },
};

/**
 * @description url字符串转换成对象
 * @param [string]
 * @returns {{}}
 */
export function urlStringToObj(string) {
  let params = {},
      q = string ? string : window.location.search.substring(1),
      e = q.split('&'),
      l = e.length,
      f,
      i = 0;
  for (i; i < l; i += 1) {
    f = e[i].split('=');
    params[f[0]] = decodeURIComponent(f[1]);
  }
  return params;
}

/**
 * @desc 格式化一个对象为字符串如 name=pat&city_no=020&old=99;
 * @param data string
 **/
export function parseParams(data) {
  if (data == null) {
    return '';
  }
  let list = [];
  Object.keys(data).forEach(item => {
    list.push(`${item}=${data[item]}`);
  });
  return list.join('&');
}

/**
 * @description 参数处理,处理一个对象,剔除其中值为空的项,返回有值的项.用在发送参数的时候处理参数对象.
 * @param object 输入的参数对象
 * @returns {*}
 */
export function cleanParams(object) {
  if (typeof object !== 'object') return false;
  let keys = Object.keys(object), res = {};
  if (keys.length) {
    keys.forEach(item => {
      switch (typeof object[item]) {
        case 'number': //数字0保留
          if (object[item] || object[item] === 0) {
            res[item] = object[item];
          }
          break;
        case 'boolean': //false保留
          res[item] = object[item];
          break;
        default: //目标参数value存在(不为null/undefined,或空字符串)
          if (object[item]) {
            res[item] = object[item];
          }
      }
    });
  }
  return res;
}

/**
 * @desc 参数为时间戳（毫秒级）或字符串“2017-02-01”。
 * @param birth
 * @return string 返回“*岁”、“*个月”、“*天”。如果参数非法则返回“/”。
 */
export function getAgeByBirthday(birth) {
  // 时间戳毫秒级“1486450898000”和字符串“2017-02-08”都能通过new Date()生成Date类型。
  let d = new Date(birth);
  if (isNaN(d.getTime())) {
    //console.warn("getAgeByBirthday()生日格式不正确", birth);
    return '/';
  }
  const birthStamp = Math.round(d.getTime() / 1000),
      nowStamp = Math.round(new Date().getTime() / 1000);
  let result = '',
      value = nowStamp - birthStamp;
  if (value < 0) {
    console.warn('getAgeByBirthday()参数出错，出生日期大于当前时间', birth);
    return '[出生日期大于当前时间]';
  } else {
    const SIGN_LIST = ['second', 'minute', 'hour', 'date', 'month', 'year'];
    let month, year, sign = 0;
    while (true) {
      switch (SIGN_LIST[sign]) {
        case 'second':
          if (value > 60) {
            value = Math.round(value / 60);
          } else {
            result = value + '秒';
          }
          sign++;
          break;
        case 'minute':
          if (value > 60) {
            value = Math.round(value / 60);
          } else {
            result = value + '分';
          }
          sign++;
          break;
        case 'hour':
          if (value > 24) {
            value = Math.round(value / 24);
          } else {
            result = value + '时';
          }
          sign++;
          break;
        case 'date':
          if (value < 30) {
            result = value + '天';
          }
          sign++;
          break;
        case 'month':
          month = Math.round(value / 30);
          if (month < 12) {
            result = month + '个月';
          }
          sign++;
          break;
        case 'year':
          //小于3岁的显示：*岁*个月 2017年4月6日11:52:00。
          year = parseInt(value / 365);
          result = year + '岁';
          if (year < 3) {
            let monthNum = parseInt((value % 365) / 31);
            if (monthNum != 0) {
              result += monthNum + '个月';
            }
          }
          break;
        default:
          result = '/';
      }
      if (result) {
        break;
      }
    }
  }
  return result;
}

/**
 * 数字转大写
 * @param num
 * @return {string}
 */
export function NumToChinese(num) {
  if (!/^\d*(\.\d*)?$/.test(num)) {
    // alert("Number is wrong!");
    return 'Number is wrong!';
  }
  const AA = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  const BB = ['', '拾', '佰', '仟', '萬', '億', '点', ''];
  let a = ('' + num).replace(/(^0*)/g, '').split('.'), k = 0, re = '';
  for (let i = a[0].length - 1; i >= 0; i--) {
    switch (k) {
      case 0: {
        re = BB[7] + re;
        break;
      }
      case 4: {
        if (!new RegExp('0{4}\\d{' + (a[0].length - i - 1) + '}$').test(a[0])) {
          re = BB[4] + re;
        }
        break;
      }
      case 8: {
        re = BB[5] + re;
        BB[7] = BB[5];
        k = 0;
        break;
      }
      default:
    }
    if (k % 4 === 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) === 0) {
      re = AA[0] + re;
    }
    if (a[0].charAt(i) != 0) {
      re = AA[a[0].charAt(i)] + BB[k % 4] + re;
      k++;
    }
  }
  if (a.length > 1) {//加上小数部分(如果有小数部分)
    re += BB[6];
    for (var i = 0; i < a[1].length; i++) {
      re += AA[a[1].charAt(i)];
    }
  }
  return re;
}

/**
 * 数字转大写
 * @param section
 * @return {string}
 */
function SectionToChinese(section) {
  let strIns = '',
      chnStr = '',
      unitPos = 0,
      zero = true;
  while (section > 0) {
    let v = section % 10;
    if (v === 0) {
      if (!zero) {
        zero = true;
        chnStr = chnNumChar[v] + chnStr;
      }
    } else {
      zero = false;
      strIns = chnNumChar[v];
      strIns += chnUnitChar[unitPos];
      chnStr = strIns + chnStr;
    }
    unitPos++;
    section = Math.floor(section / 10);
  }
  return chnStr;
}

export function convertNumberToChinese(num) {
  let unitPos = 0,
      strIns = '',
      chnStr = '',
      needZero = false;
  if (num === 0) {
    return chnNumChar[0];
  }
  while (num > 0) {
    let section = num % 10000;
    if (needZero) {
      chnStr = chnNumChar[0] + chnStr;
    }
    strIns = SectionToChinese(section);
    strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];
    chnStr = strIns + chnStr;
    needZero = (section < 1000) && (section > 0);
    num = Math.floor(num / 10000);
    unitPos++;
  }
  return chnStr;
}

/**
 * 转为大写（发票）
 * @param money
 * @return {string}
 */
export function convertCurrency(money) {
  //汉字的数字
  let cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
  //基本单位
  let cnIntRadice = ['', '拾', '佰', '仟'];
  //对应整数部分扩展单位
  let cnIntUnits = ['', '万', '亿', '兆'];
  //对应小数部分单位
  let cnDecUnits = ['角', '分', '毫', '厘'];
  //整数金额时后面跟的字符
  let cnInteger = '整';
  //整型完以后的单位
  let cnIntLast = '元';
  //最大处理的数字
  let maxNum = 999999999999999.9999;
  //金额整数部分
  let integerNum;
  //金额小数部分
  let decimalNum;
  //输出的中文金额字符串
  let chineseStr = '';
  //分离金额后用的数组，预定义
  let parts;
  if (!money) {
    return '';
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return '';
  }
  if (money === 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf('.') === -1) {
    integerNum = money;
    decimalNum = '';
  } else {
    parts = money.split('.');
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    let zeroCount = 0;
    let IntLen = integerNum.length;
    for (let i = 0; i < IntLen; i++) {
      let n = integerNum.substr(i, 1);
      let p = IntLen - i - 1;
      let q = p / 4;
      let m = p % 4;
      if (n == '0') {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != '') {
    let decLen = decimalNum.length;
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1);
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == '') {
    chineseStr += cnInteger;
  }
  return chineseStr;
}

/**
 * @description 是否为浏览器环境
 */
export function isBrowser() {
  return !!(typeof document !== 'undefined' && window);
}

//延迟
export const sleep = async (ms) => {
  return await new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

//从数组中筛选属性为prop的对象，返回新数组
export const filterArray = (arr, prop) => {
  if (arr.length === 0) return [];
  return arr.filter(item => {
    return item[prop] !== undefined && item[prop] !== '';
  });
};
//通过属性查找节点
export const attrToItem = (attrName, value, list) => {
  if (list && list.length) {
    return list.find((item) => {
      return item[attrName] == value;
    });
  } else {
    return false;
  }
};
//获取Cache class_name
export const getCacheName = (cacheId, id, state, att = 'id') => {
  let item = attrToItem(att, id, state.Cache[cacheId].data);
  if (item) {
    return item.class_name;
  }
  return null;
};
//获取Cache item
export const getCacheItem = (cacheId, id, state, att = 'id') => {
  let item = attrToItem(att, id, state.Cache[cacheId].data);
  if (item) {
    return item;
  }
  return null;
};
//输入text是否存在非空字符
export const isExist = (text) => {
  let p = /\S+/g;
  return p.test(text);
};
//输入一个数组和一个属性名，筛选出其中的某一项
export const filterArrVal = (arr, prop, val) => {
  return arr.filter(item => (item[prop] == val));
};
//图片src转base64
const getBase64Image = (img) => {
  let canvas = document.createElement('canvas');
  canvas.width = img.width;
  canvas.height = img.height;
  let ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, img.width, img.height);
  let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
  return canvas.toDataURL('image/' + ext);
};
export const loadImageAsync = async (url) => {
  return await new Promise(resolve => {
    let img = new Image();
    img.crossOrigin = '';
    img.src = url;
    img.onload = function() {
      var base64 = getBase64Image(img);
      resolve(base64);
    };
  });
};

/**
 * @author xieyihao
 * @说明 返回URL后面的参数，直接传入window.location.search
 * 使用了 encodeURI()和decodeURI()
 * @Object
 */
export function getRequest(searchString) {
  let theRequest = {};
  if (searchString.indexOf('?') != -1) {
    let str = searchString.substr(1);
    let strs = str.split('&');
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURI(strs[i].split('=')[1]);
    }
  }
  return theRequest;
}

/**
 * 自定义错误类型。
 * 1.接口数据异常（json.status不为0）;
 * 2.服务异常，response返回可能非200.
 * 3.业务操作异常。建议使用message提示。
 */
export function InterfaceError(title, {status, msg, content}) {
  this.name = 'Interface Error';
  this.title = title || '查询异常';
  this.status = status || '';//interfaceState
  this.msg = msg || '';
  this.content = content || '';
  this.stack = (new Error()).stack;
}

InterfaceError.prototype = Object.create(Error.prototype);
InterfaceError.prototype.constructor = InterfaceError;

export function ServiceError(title, {msg}) {
  this.name = 'Service Error';
  this.title = title || '服务异常';
  this.msg = msg || '';
  this.stack = (new Error()).stack;
}

ServiceError.prototype = Object.create(Error.prototype);
ServiceError.prototype.constructor = ServiceError;

export function ActionError(title, {msg}) {
  this.name = 'Action Error';
  this.title = title || '业务流程异常';
  this.msg = msg || '';
  this.stack = (new Error()).stack;
}

ActionError.prototype = Object.create(Error.prototype);
ActionError.prototype.constructor = ActionError;

/**
 * 手机号脱敏处理
 * @param phone
 * @returns {string | void | *}
 */
export function safePhone(phone) {
  if (!phone) return phone;
  const pat = /(\d{3})\d*(\d{4})/;
  return `${phone}`.replace(pat, '$1****$2');
}

/**
 * 姓名脱敏
 */
export function safeName(name, frontLen = 1, endLen = 1) {
  if (!name || typeof name != 'string') return name;
  if (name.length <= 2) {
    return name.substring(0, 1) + '*';
  }

  let len = name.length - frontLen - endLen;
  let res = '';
  for (let i = 0; i < len; i++) {
    res += '*';
  }
  return name.substring(0, frontLen) + res + name.substring(name.length - endLen);
}

/**
 * 字符串缩短脱敏
 * @param {String} str
 * @param {Number} len
 * @param {Boolean} isEncrypt
 * @return {String}
 */
export function shortenStr(str, len = 10, isEncrypt = false) {
  if (str && str.length > len) {
    return str.substring(0, len) + (isEncrypt ? (Array(str.length - len).fill('*').join('')) : '...');
  }
  return str;
}

//选中文本
export const selectText = (selector) => {
  let text = document.querySelector(selector);
  if (document.body.createTextRange) {
    let range = document.body.createTextRange();
    range.moveToElementText(text);
    range.select();
  } else if (window.getSelection) {
    let selection = window.getSelection();
    let range = document.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange(range);
    /*if(selection.setBaseAndExtent){
        selection.setBaseAndExtent(text, 0, text, 1);
    }*/
  } else {
    console.error('没有找到可选中的文本');
  }
};

//是否为空对象
export function isEmptyObject(e) {
  let t;
  for (t in e) {
    return !1;
  }
  return !0;
}

//处理阿里医院接口string类型的返回数据
export const handleAliStringResp = (resp)=>{
  if(!resp) return {};
  Object.keys(resp).forEach(key=>{
    if(typeof resp[key] === 'object' && "string" in resp[key]){
      resp[key] = resp[key].string;
    }
  })
  return resp;
}
