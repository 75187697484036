import {host} from "src/utils/hostConf";
import {dispatchWithReturn, get, post} from "src/utils/baseRequest";

export const SET_USER_INFO = "SET_USER_INFO";
export const CLEAN_USER_INFO = "CLEAN_USER_INFO";
export const RECEIVE_DOCTOR_DETAIL = "RECEIVE_DOCTOR_DETAIL";
export const RECEIVE_ASSISTANT_DETAIL = "RECEIVE_ASSISTANT_DETAIL";
export const RECEIVE_DOCTOR_ONLINE_STATUS = "RECEIVE_DOCTOR_ONLINE_STATUS";


//保存用户数据到redux
export const setUserInfo = (data) => ({
  type: SET_USER_INFO,
  payload: data
});

export const cleanUserInfo = () => ({
  type: CLEAN_USER_INFO, //redux-persist 会缓存userInfo, 所以需要内存也清理掉
})

//cas 登出,redux版本
export const reqCASLogout = (data) => async (dispatch, getState) => {
  return get({url: `${host.cHost}cgi-bin/auth/loginout`, data}).then(json => {
    return json;
  });
};

//cas检查登录
export const reqCASCheckLogin = (data) => {
  return get({url: `${host.cHost}cgi-bin/auth/checklogin`, data}).then(json => {
    return json;
  });
};

//cas登出
export const requestCASLogout = (data) => {
  return get({url: `${host.cHost}cgi-bin/auth/loginout`, data}).then(json => {
    return json;
  });
};

//获取阿里医生详情,挂在UserInfo:ali下面
export const reqAliUserDetail = ({doctor_id}) => async (dispatch, getState) => {
  const postData = {
    doctor_id,
    info_type: '3', //逻辑或 1: 查出医生详情 2:查出医生疾病
  }
  return get({url: `${host.cGo}aliim/handler/queryAliimDoctorDetail`, data: postData}).then(json => {
    if (!json.isOK) {
      throw json;
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_DOCTOR_DETAIL, payload: json});
  });
};

export const reqAliUpdateOnlineState = (state) => async (dispatch, getState) => {
  let postData = {
    doctor_id: getState().UserInfo.doctor_id,
    status: state ? 0 : -1, //0上架，-1下架
  }
  return post({url: `${host.cGo}aliim/handler/uploadDoctorStatus`, data: postData}).then(json => {
    if (!json.isOK) {
      throw json;
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_DOCTOR_ONLINE_STATUS, payload: json});
  });
}

//获取医助详情,挂在userInfo:asst 下面
export const reqAsstDetail = ({asst_id}) => async (dispatch, getState) => {
  const postData = {
    asst_id,
    info_type: '1',
  }
  return get({url: `${host.cGo}aliim/handler/queryAliimDoctorAssistRelation`, data: postData}).then(json => {
    if (!json.isOK) {
      throw json;
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_ASSISTANT_DETAIL, payload: json});
  });
};
