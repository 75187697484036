/**
 * 嵌入页 用户
 */

import './index.scss';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {List, Modal, Switch, Toast} from 'antd-mobile';
//import StoreContext from 'src/store/context';
import TabPane from 'src/components/TabPane/index';
import {reqAliUpdateOnlineState, reqAliUserDetail, reqAsstDetail, reqCASLogout} from 'src/store/actions/Account';
import {cleanUser} from 'src/utils/user';
import defAvatar from 'src/assets/img/avatar.png';

const Item = List.Item;

function MyPage(props) {
  //useRemoveIOSScroll(); //移除IOS外框弹性
  //const {userInfo} = useContext(StoreContext);
  const {ali, asst, doctor_name, doctor_id, doctor_info, user_name, asst_id} = props.userInfo;
  const {dispatch} = props;
  let isDoc = !!doctor_id, isAsst = !!asst_id;
  useEffect(() => {
    if (isDoc) {
      dispatch(reqAliUserDetail({doctor_id})).catch(err => {
        Toast.fail(err.detail);
      });
    }
    if (isAsst) {
      dispatch(reqAsstDetail({asst_id})).catch(err => {
        Toast.fail(err.detail);
      });
    }

  }, [isDoc, isAsst, doctor_id, asst_id, dispatch]);
  const reqLogout = () => {
    Toast.loading(null, 0);
    props.dispatch(reqCASLogout()).finally(() => {
      Toast.hide();
      cleanUser();
      window.location.href = '/'; //window.location.pathname;
    }).catch(err => {
      Toast.hide();
      Toast.fail(err.detail || err.message);
    });
  };
  const onClickLogout = (e) => {
    Modal.alert('登出', '确认退出登录吗?', [
      {text: '取消', style: 'default'},
      {text: '退出', onPress: reqLogout},
    ]);
  };
  const onSwitchOnlineState = (curState) => {
    Toast.loading('更新中...', 0);
    dispatch(reqAliUpdateOnlineState(!curState)).then(resp => {
      if (!resp.isOK) {
        Toast.fail(resp.detail);
      }
    }).finally(() => {
      Toast.hide();
    });
  };
  const {user_pic_url} = ali.aliim_doctor_detail || {};
  const {status} = ali;
  const doctorHeader = (<div className="doctor-info">
    <div className="doctor-avatar" style={{backgroundImage: `url(${user_pic_url || defAvatar})`}}>
      {/*<img src={user_pic_url || defAvatar} alt=""/>*/}
    </div>
    {/*<p>{user_name} [{user_id}]</p>*/}
    <p className="name">{doctor_name} [{doctor_id}]</p>
    <ul className="info">{doctor_info && doctor_info.map(info => {
      return <li key={info.mis_doctor_id}>{info.city_name} {info.clinic_name}</li>;
    })}</ul>
  </div>);
  const assistantHeader = (<div className="doctor-info">
    <div className="doctor-avatar" style={{backgroundImage: `url(${user_pic_url || defAvatar})`}}></div>
    <p className="name">{user_name} [{asst_id}] <small>医助</small></p>
    <ul className="info">{doctor_info.map(info => {
      return <li key={info.mis_doctor_id}>{info.city_name} {info.clinic_name}</li>;
    })}</ul>
  </div>);
  return (
    <TabPane wrapperClassName="inner-content my">
      {isDoc ? doctorHeader : null}
      {isAsst ? assistantHeader : null}
      <br/>
      {
        isDoc ? (<List renderHeader={() => '个人设置'} className="online-state">
          <Item extra={<Switch onChange={() => {
            onSwitchOnlineState(status >= 0);
          }} checked={status >= 0}/>}>在线状态</Item>
        </List>) : null
      }
      {
        isAsst ? (<List renderHeader={() => '助理医生'} className="asst-doctor-list">
          {asst && asst.length ? asst.map(i => {
            return <Item key={i.id}>{i.detail.doctor_name}[{i.doctor_id}]</Item>;
          }) : <Item>暂无助理医生</Item>}
        </List>) : null
      }
      <br/>
      <List className="logout">
        <Item className="logout" onClick={onClickLogout}>退出</Item>
      </List>
    </TabPane>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.UserInfo,
  };
};
export default connect(mapStateToProps)(MyPage);
