/**
 * 会话嵌入页
 */

import './index.scss';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
//import StoreContext from 'src/store/context';
import {Toast} from 'antd-mobile';
import TabPane from 'src/components/TabPane/index';
import ChatItem from 'src/components/SessionItem/index';
import ChatTop from 'src/components/SessionTop/index';
import EmptyList from 'src/components/EmptyList/index';
import {reqSessionList} from 'src/store/actions/Message';
import {useRemoveIOSScroll} from '../../../utils/hooks';

function ChatPage(props) {
  //useRemoveIOSScroll(); //移除IOS外框弹性
  //const {userInfo: {doctor_id}} = useContext(StoreContext);
  const {
    userInfo: {doctor_id, asst_id},
    message: {connected, sessionList, sessionListObj},
    dispatch,
  } = props;

  useEffect(() => {
    function pageInit() {
      Toast.loading('加载中...', 0);
      dispatch(reqSessionList({doctor_id, asst_id})).then(json => {
        Toast.hide();
      }).catch(e => {
        Toast.fail(e.detail);
      });
    }

    pageInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor_id]);

  const renderList = (list, listObj) => {
    return list.length ? list.map(id => {
      return (<ChatItem key={id} data={listObj[id]}/>);
    }) : <EmptyList text="暂无订单记录"/>;
  };
  return (
    <TabPane wrapperClassName="inner-content chat">
      <ChatTop>
        {connected ? '消息服务已连接' : '消息服务断开'}
      </ChatTop>
      {renderList(sessionList, sessionListObj)}
    </TabPane>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.UserInfo,
    message: state.Message,
  };
};
export default connect(mapStateToProps)(ChatPage);

