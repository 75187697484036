//reducer Index

import {combineReducers} from 'redux';
import {UserInfo} from './Account';
import {Message} from './Message';
import {OrderDetail} from './Order';
import {GlobalFeedbackInfo} from './GlobalFeedback'; //全局的错误提示处理

const rootReducer = combineReducers({
  UserInfo,
  Message,
  OrderDetail,
  GlobalFeedbackInfo,
});

export default rootReducer;
