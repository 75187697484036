/**
 * @author simon
 * @Description: 路由
 */
import React from 'react';
import {Redirect} from 'react-router-dom';
import Root from './containers/Application/index';
import Home from './containers/Home/index';
import Conversation from './containers/Conversation/index';
import NotFound from './containers/NotFound';

import Test from './containers/Test'; //模板文件

const routes = [
  {
    component: Root,
    routes: [
      {
        path: '/',
        exact: true,
        render: () => (
          <Redirect to={'/chat'}/>
        ),
      },
      {
        path: '/:tab(chat|contact|my)?', //应用直接进入 首页
        exact: true,
        component: Home,
        isList: true,
      },
      {
        path: '/chat/:id', //会话
        exact: true,
        component: Conversation,
      },
      {
        path: '/test',
        exact: true,
        component: Test,
        /*routes: [
          {
            path: "/child/:id/grand-child",
            component: GrandChild
          }
        ]*/
      },
      {
        component: NotFound,
        path: '*',
      },
    ],
  },
];

export default routes;
