/**
 * 订单内容
 */
import './index.scss';
import React, {useEffect, useState} from 'react';
import Zmage from 'react-zmage'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Icon, Modal, Toast} from 'antd-mobile';

/*let data = {
    ali_doctor_uuid: '3248e5202e49c33dc1a3b21f6ae6abe81601363320081',
    ali_patient_uuid: 'czerZkX7LD+k3Y0bAaAEoPrJfN3Rxtn/ckyuVyeLpuY=',
    dialog_close_reason: '',
    doctor_id: '117532',
    doctor_name: '罗明',
    last_msg: '[语音]',
    last_msg_at: '1602656613',
    order_id: '2010130038402731',
    order_rsp: {
      close_account_time: '',
      comment: '',
      diagnosing_time: '',
      doctor_id: '3248e5202e49c33dc1a3b21f6ae6abe81601363320081',
      doctor_name: '罗明',
      item_id: '628757098883',
      item_type: '图文咨询',
      label_names: '',
      medical_info: {
        abnormal_liver_function_detail: '',
        abnormal_renal_function_detail: '',
        age: '359',
        allergies_description: '',
        allergies_details: null,
        chief_complaint: '膝盖窝窝处疼痛测试',
        city: '广州市',
        diagnosed_diseases: null,
        disease_pictures: [
          'https://image-alihealth-inner.alihealth.cn/diagnoseimages/7n5go5chtvs-c83b8ffcb0d5.png?Expires=1602565139&OSSAccessKeyId=droMW0hhLqW7CcSn&Signature=1ZkHtaHlMEGqr1mEYlMaZgKbQ5Q%!D(MISSING)'
        ],
        expected_confinement_date: '',
        family_history_description: '',
        family_history_details: null,
        has_allergies_history: false,
        has_family_history: false,
        has_past_history: false,
        is_liver_function_abnormal: false,
        is_pregnant: false,
        is_renal_function_abnormal: false,
        past_history_description: '',
        past_history_details: null,
        pregnant_type: '',
        present_illness_history: '',
        sex: '男'
      },
      order_create_time: '2020-10-13 11:32:37',
      order_finish_time: '',
      order_id: '2010130038402731',
      order_status: 'WAIT_DIAGNOSE',
      patient_id: 'czerZkX7LD+k3Y0bAaAEoPrJfN3Rxtn/ckyuVyeLpuY=',
      review_create_time: '',
      score: 0,
      session_id: '7d4cfd9e0c6cfeba709526c0a1241b04',
      total_fee: '1.0'
    },
    order_status: 'DIAGNOSING',
    patient_name: '29岁-男 | 膝盖窝窝处疼痛测试',
    session_id: '7d4cfd9e0c6cfeba709526c0a1241b04',
    unchecked: 0
  }*/

const OrderDetail = (props) => {
  const {visible, onClose, orderDetail} = props;
  let {
    age, sex, city, chief_complaint, present_illness_history, diagnosed_diseases,//通用
    has_allergies_history, allergies_description, allergies_details, //过敏史
    has_past_history, past_history_description, past_history_details, //既往史
    has_family_history, family_history_description, family_history_details, //家族史
    is_liver_function_abnormal, abnormal_liver_function_detail, //肝功能
    is_renal_function_abnormal, abnormal_renal_function_detail, //肾功能
    is_pregnant, pregnant_type, expected_confinement_date, //孕产
    disease_pictures, //图片
  } = orderDetail.data.order_rsp.medical_info;

  const renderBodyFunction = (isOK, desc) => {
    return <React.Fragment><span>{isOK ? '异常' : '正常'}</span> {desc}</React.Fragment>
  }

  const renderList = (obj) => {
    if (!obj) return null;
    if (obj && obj.length) {
      return obj.map((item, idx) => {
        return <span key={idx}>{item}</span>
      })
    }
    return null;
  }

  const renderImgList = (imgObj) => {
    if (!imgObj) return null;
    const onImgErr = (e) => {
      e.target.src = `/assets/img-error.jpg`; //指向 public/assets/img-error.jpg,是从public目录直接拷贝过去的
    }
    return imgObj && imgObj.length ? (<div className="image-box">
      {imgObj.map((img, idx) => {
        return (<Zmage preset={"mobile"} key={idx} src={img} alt="" onError={onImgErr}/>)
      })}
    </div>) : (<div>暂无图片</div>)
  }

  const renderFooter = () => {
    //todo 按订单情况输出是否拒诊的按钮
    if(!orderDetail.isOK) return [];
    return [{
      text: '拒诊', onPress: () => {
        /*Modal.alert('拒诊', '确认要拒诊吗?', [
          {text: '取消', style: 'default'},
          {text: '确认拒诊', onPress: props.onRefuse},
        ])*/
        Modal.prompt('确定要拒诊吗?', '拒诊请输入原因,否则请取消',
          [
            {text: '取消', style: 'default'},
            {
              text: '确定拒诊',
              onPress: value => {
                props.onRefuse(value)
              },
            },
          ],
          'default', null, ['拒诊原因'])
      }
    }]
    // or return null;
  }

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      popup={true}
      //footer={renderFooter()}
      wrapClassName="order-detail-wrapper"
    >
      <div className="order-detail">
        {orderDetail.isOK ?
          (<dl>
            <dt>患者信息</dt>
            <dd>{Math.floor(age / 12)}岁 / {sex} / {city}</dd>
            <dt>主诉</dt>
            <dd>{chief_complaint}</dd>
            <dt>现病史</dt>
            <dd>{present_illness_history} {diagnosed_diseases}</dd>
            {has_allergies_history ? (
              <React.Fragment>
                <dt>过敏史</dt>
                <dd>{renderList(allergies_details)} {allergies_description}</dd>
              </React.Fragment>
            ) : null}
            {has_past_history ? (
              <React.Fragment>
                <dt>既往史</dt>
                <dd>{renderList(past_history_details)} {past_history_description}</dd>
              </React.Fragment>
            ) : null}
            {has_family_history ? (
              <React.Fragment>
                <dt>家族史</dt>
                <dd>{renderList(family_history_details)} {family_history_description}</dd>
              </React.Fragment>
            ) : null}
            <dt>肝肾功能</dt>
            <dd>肝功能: {renderBodyFunction(is_liver_function_abnormal, abnormal_liver_function_detail)}</dd>
            <dd>肾功能: {renderBodyFunction(is_renal_function_abnormal, abnormal_renal_function_detail)}</dd>
            {sex === '女' ? (
              <React.Fragment>
                <dt>孕产信息</dt>
                <dd>{is_pregnant ? '有' : '无'}备孕/妊娠/哺乳期, {pregnant_type}, {expected_confinement_date}</dd>
              </React.Fragment>
            ) : null}
            <dt>疾病图片</dt>
            <dd>{renderImgList(disease_pictures)}</dd>
          </dl>) :
          (orderDetail.detail ? <div className="error-tip">{orderDetail.detail}</div> :
            <div className="error-tip"><Icon type="loading"/> 加载中...</div>)
        }
      </div>
    </Modal>
  );
}

OrderDetail.prototype = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

const mapStateToProps = (state, ownProps) => {
  return {
    orderDetail: state.OrderDetail,
  }
}
export default connect(mapStateToProps)(OrderDetail)
