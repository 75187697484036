
//订单状态/会话状态
export const SESSION_STATUS = {
  'WAIT_DIAGNOSE': '新订单通知',
  'DIAGNOSING': '医⽣接诊',
  'DIAGNOSED': '问诊结束',
  'REFUNDED': '退款成功',
  'THIRD_SELLER_PAID': '已结算',
  'THIRD_SELLER_PAI': '已结算',
  'REVIEWED': '患者完成服务评价',
};

//医生上下线状态
export const ONLINE_STATUS = {
  '0':'上架',
  '-1':'下架',
}
//来源代码(对应接口上的source字段)
export const SOURCE_CODE = {
  tx: '腾讯医疗',
  ali: '阿里健康'
}
