/**
 * 检查登录模块
 */
import './index.scss';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {ActivityIndicator, Modal, Toast} from 'antd-mobile';
import {storage} from 'src/utils/tools';
import {cleanUser} from 'src/utils/user';
import {reqCASCheckLogin, requestCASLogout, setUserInfo} from 'src/store/actions/Account';

const systemKeys = ['asst_id', 'city_id', 'city_name', 'clinic_id', 'clinic_name', 'user_id', 'user_name', 'work_no']; //本系统data中的使用字段, 不再从data中取其他的字段存储
const doctorKeys = ['dept_name', 'doctor_id', 'doctor_user_id', 'mis_doctor_id', 'name']; //医生中的信息

class LoginBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingText: '加载中...',
    };
  }

  componentDidMount() {
    this.checkLogin();
    this.setState({loading: true});
  }

  componentWillUnmount() {
    this.setState({loading: false});
  }

  cleanAndGo() {
    storage.clear('session');
    storage.clear('local');
    cleanUser();
    window.location.href = '/';
  }

  logout = () => {
    requestCASLogout({ticket: this.props.ticket}).then(resp => {
      if (resp.status === 0) {
        console.log('注销成功');
        this.cleanAndGo();
      } else {
        Toast.fail(`注销失败 [${resp.status}] ${resp.message}`, 3, this.cleanAndGo);
      }
    });
  };

  checkLogin() {
    const {ticket, onSigned} = this.props;
    let href = window.location.href;
    let url = href.indexOf('?ticket=') > 0 ?
      href.slice(0, href.indexOf('?ticket=')) :
      (href.indexOf('&ticket=') > 0 ? href.slice(0, href.indexOf('&ticket=')) : href);
    let domains = {
      host: window.location.host,
      hostname: window.location.hostname,
      redirect_uri: encodeURIComponent(url),//去除ticket=xxx
      gst: '.gstzy.cn',
    };
    this.setState({loadingText: '检查登录中...'});
    reqCASCheckLogin({ticket}).then(resp => {
      //0 成功, 1没登录 ,2 ticket无效 ,3 设置cookie失败 ,4 没权限
      switch (resp.status) {
        case 0:
          this.setState({loadingText: '登录成功'});
          let {data: login_info, doctor_info, pharmacy_info} = resp;
          let userInfo = {doctor_info}; //用户信息初始值
          systemKeys.forEach(key => {
            //写入data信息
            if (login_info.hasOwnProperty(key)) {
              userInfo[key] = login_info[key];
            }
          });
          //写入医生信息
          if (doctor_info && doctor_info.length) {
            //如果医生信息存在, 默认取第一个信息作为医生当前门店的信息
            userInfo.doctor_user_id = login_info.user_id;
            let info = doctor_info[0];
            Object.keys(info).forEach(key => {
              userInfo[key] = info[key];
            });
            // userInfo['shop_no'] = info['clinic_id'];
            // userInfo['mis_doc_id'] = info['mis_doctor_id'];
            this.props.dispatch(setUserInfo(userInfo));//存储数据到内存
          } else if (userInfo.asst_id) {
            Toast.info('用户仅为医助');
          } else {
            Modal.alert(
              '登陆错误',
              '医生未在任何一个门店出诊，请联系客服关联账号',
              [{text: '注销', onPress: this.logout}],
            );
          }
          console.log('loginBox--->userInfo', userInfo);

          //存储数据到内存
          this.props.dispatch(setUserInfo(userInfo));
          //保存当前用户信息到session
          storage.set('user_info', userInfo, 'session');
          //运行登录成功回调
          onSigned({userInfo, loginInfo: login_info, doctorInfo: doctor_info});
          break;
        case 1:
        case 2:
          this.setState({loadingText: '正在跳转去登录...'});
          if (resp.login_url) {
            window.location.href = `${resp.login_url}?host=${domains.host}&redirect_uri=${domains.redirect_uri}`;
          } else {
            Modal.alert(
              '登录失败',
              '登录接口出错,无登录地址',
              [{text: '确定', onPress: this.cleanAndGo}],
            );
          }
          break;
        case 3:
          this.setState({loading: false});
          Modal.alert(
            '登录失败',
            'cookie设置失败,请检查浏览器设置并重新登录',
            [{text: '确定', onPress: this.cleanAndGo}],
          );
          break;
        case 4:
          this.setState({loading: false});
          Modal.alert(
            '登录失败',
            '您当前无权访问该页面,请联系您的管理员',
            [{text: '确定', onPress: this.cleanAndGo}],
          );
          break;
        case 6:
          //get SessionInfo failed
          this.setState({loading: false});
          if (resp.login_url) {
            window.location.href = `${resp.login_url}?host=${domains.host}&redirect_uri=${domains.redirect_uri}`;
          } else {
            Modal.alert(
              '登录失败',
              `[${resp.status}]${resp.message}`,
              [{text: '确定', onPress: this.cleanAndGo}],
            );
          }
          break;
        default:
          this.setState({loading: false});
          Modal.alert(
            '登录失败',
            `信息:[${resp.status}]${resp.message}`,
            [{text: '确定', onPress: this.cleanAndGo}],
          );
      }
    }).catch(err => {
      Toast.fail(`[接口错误] ${err.message}`, 5);
    });
  }

  render() {
    const {loadingText, loading} = this.state;
    return (
      <div className="component login-box">
        <ActivityIndicator toast text={loadingText} size="large" animating={loading}/>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.UserInfo,
  };
};
export default connect(mapStateToProps)(LoginBox);
