/**
 * Page 容器, 非Tab页面都使用该容器,以接入标题栏
 */

import './index.scss';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {Icon, NavBar} from 'antd-mobile';
import GIcon from '../GIcon';

export const NavBack = withRouter(({history}) => (<Icon type="left" onClick={() => {
  history.goBack()
}}/>))

export const NavHome = withRouter(({history}) =>(<GIcon type="home" className="nav-home" onClick={() => {
  history.push('/')
}}/>))

const Page = (props) => {
  const {pageTitle = '固生堂', navLeft, navRight = null, wrapperClassName, children, history} = props;
  return (
    <div className={`app ${wrapperClassName}`}>
      <NavBar
        className="site-nav"
        leftContent={navLeft || <NavBack/>}
        rightContent={navRight}
      > {pageTitle} </NavBar>
      {children}
    </div>
  );
}

export default Page;
