/**
 * 底部输入框
 */

import './index.scss';
import React, {useEffect, useRef, useState} from 'react'
import {Button, List, Modal, TextareaItem, Toast} from 'antd-mobile'
import GIcon from '../GIcon';
import AudioBar from './AudioBar';
import {uploadImData} from '../../store/actions/Message'

const ChatBar = (props) => {
  const inputEl = useRef(null);
  const diagnoseEl = useRef(null);
  const adviceEl = useRef(null);
  const fileEl = useRef(null)
  const [content, setContent] = useState(null);
  const [keyMode, setKeyMode] = useState(true); //键盘模式还是语音模式
  const [showMask, setShowMask] = useState(false);
  const [showDiagnose, setShowDiagnose] = useState(false);

  useEffect(() => {
    function hideMask(){
      setShowMask(false);
    }

    document.querySelector('.conversation-content').addEventListener('click', hideMask)
    return () => {
      document.querySelector('.conversation-content').removeEventListener('click', hideMask)
    }
  }, [])

  const onClickSend = () => {
    const {onSend} = props
    if (typeof onSend === 'function' && content.trim()) {
      onSend(content)
      inputEl.current.clearInput();
      setContent(null);
    }
  }

  const fileChange = e => {
    const file = e.target.files[0]
    const content_type = e.target.files[0].type
    const reader = new FileReader()
    const img = new Image()
    let base64 = null
    reader.readAsDataURL(file)
    reader.onload = event => {
      if (file.size > 500 * 1024) {
        img.src = event.target.result
        img.onload = () => {
          const {width, height} = img
          let canvas = document.createElement('canvas');
          let context = canvas.getContext('2d');
          canvas.width = 1000;
          canvas.height = 1000 * height / width;
          // 核心JS就这个
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          base64 = canvas.toDataURL(content_type).split('base64,')[1]
          uploadImg()
        }
      } else {
        base64 = event.target.result.split('base64,')[1]
        uploadImg()
      }

      async function uploadImg(){
        const {doctorId: doctor_id, onSend} = props
        Toast.loading('发送中...', 0);
        try {
          const res = await uploadImData({doctor_id, content_type, file: base64})
          setShowMask(false)
          if (res.isOK) {
            onSend(
              [res.data.object_key],
              '2'
            )
          } else {
            Toast.fail(res.detail)
          }
        } catch(error) {
          Toast.fail('发送失败:' + error.status + 'detail:' + error.detail || '')
        }
        fileEl.current.value = ''
      }
    }
  }

  const onRecord = async (file, duration) => {
    //console.log('aaaa', file,duration)
    const {doctorId: doctor_id, onSend} = props
    const res = await uploadImData({doctor_id, content_type: "audio/mp3", file: file})
    //console.log('kkk',res);
    if (res.isOK) {
      onSend(
        [res.data.object_key, duration],
        '3'
      )
    } else {
      Toast.fail(res.detail)
    }
  }

  const sendDiagnose = () => {
    const {onSend} = props
    const diagnose = diagnoseEl.current.state.value.trim()
    const advice = adviceEl.current.state.value.trim()
    if (diagnose && advice) {
      onSend([diagnose, advice], '31')
      diagnoseEl.current.clearInput()
      adviceEl.current.clearInput()
      setShowDiagnose(false);
    }
  }

  const nodeButtonSend = <Button type="primary"
                                 className={`bar-btn-send`}
                                 size="small"
                                 onClick={onClickSend}>发送</Button>
  const nodeIconPlus = <GIcon type="round-add" className="bar-btn-more" onClick={() => {
    setKeyMode(true); //切换为键盘
    setShowMask(true);//打开更多栏
  }}/>
  const audioBtn = <GIcon type="sound" className="bar-btn-audio" onClick={() => {
    setKeyMode(false);
    setShowMask(false);
  }}/>
  const keyboardBtn = <GIcon type="keyboard" className="bar-btn-keyboard" onClick={() => {
    setKeyMode(true);
    setShowMask(false);
  }}/>
  return (
    <footer className="chat-bar">
      <div className="bar-mode">
        {keyMode ? audioBtn : keyboardBtn}
      </div>
      {keyMode ? <TextareaItem
        autoHeight
        className="bar-input"
        labelNumber={5}
        ref={inputEl}
        onChange={(value) => {
          setContent(value)
        }}
        onKeyPress={(e) => {
          if (e.keyCode === 13 || e.key === 'Enter' || e.which === 13) {
            e.preventDefault();
            onClickSend();
          }
        }}
        onFocus={() => {
          setShowMask(false)
        }}
      /> : <AudioBar onRecord={onRecord}/>}
      <div className="bar-action">{content ? nodeButtonSend : (showMask ? keyboardBtn : nodeIconPlus)}</div>
      <div className={`bar-more ${showMask ? 'up' : ''}`}>
        <div className="item">
          <GIcon type="pic" className="icon">
            <input type="file" ref={fileEl} onChange={e => fileChange(e)}/>
          </GIcon>
          <span>相册</span>
        </div>
        <div className="item" onClick={() => {
          setShowDiagnose(true);
          setShowMask(false)
        }}>
          <GIcon type="form" className="icon"/>
          <span>小结</span>
        </div>
      </div>
      <Modal
        className="diagnose-modal"
        title="诊断小结"
        transparent={true}
        closable={true}
        onClose={() => setShowDiagnose(false)}
        visible={showDiagnose}
        footer={[{text: '发送', onPress: () => sendDiagnose()}]}
      >
        <List renderHeader={() => '医师诊断：'}>
          <TextareaItem
            rows={4}
            labelNumber={5}
            ref={diagnoseEl}
          />
        </List>
        <List renderHeader={() => '医师建议：'}>
          <TextareaItem
            rows={4}
            labelNumber={5}
            ref={adviceEl}
          />
        </List>
      </Modal>
    </footer>
  )
}

export default ChatBar;
