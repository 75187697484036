import {ActionError, InterfaceError, ServiceError} from "src/utils/tools";

export const SHOW_ERROR_INFO = "SHOW_ERROR_INFO";

export const showErrorInfo = (data) => (dispatch, getState) => {
  const {key} = getState().GlobalFeedbackInfo;
  data.key = key;
  if (data.key > 10000) {
    data.key = 1;
  } else {
    data.key = data.key + 1;
  }
  dispatch({
    type: SHOW_ERROR_INFO,
    json: data,
  });
};


/**
 * @desc UI层面的异常处理中间层。
 * @param middleware
 * @return {function(*=, *=)}
 */
export function errorHandlerFactory(middleware) {
  return async (dispatch, getState) => {
    try {
      return await middleware(dispatch, getState);
    } catch (e) {
      if (e instanceof InterfaceError) {
        dispatch(showErrorInfo({
          type          : "notification",
          title         : e.title,
          interfaceState: {
            status : e.status,
            message: e.msg,
          }
        }));
        return e;
      } else if (e instanceof ServiceError) {
        dispatch(showErrorInfo({
          type   : "modal",
          title  : e.title,
          content: e.msg,
        }));
        return e;
      } else if (e instanceof ActionError) {
        dispatch(showErrorInfo({
          type   : "message",
          title  : e.title,
          content: e.msg,
        }));
        return e;
      } else {
        console.error("【Throw Error】", e);
      }
      return false;
    }
  }
}
