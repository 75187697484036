/**
 * 程序主页面,Tab 承载页
 */

import './index.scss';
import React, {useContext, useEffect} from 'react';
//import StoreContext from '../../store/context';
import {Icon, NavBar, Popover, TabBar} from 'antd-mobile';
import GIcon from 'src/components/GIcon.jsx';
import Chat from './ChatList/index';
//import Contact from './Contact/index';
import My from './My/index';
import {connect} from 'react-redux';
import theme from 'src/assets/theme';

const TabBarItem = TabBar.Item;
const PopoverItem = Popover.Item;
const TABS = {
  CONVERSATION: 'chat',
  CONTACT: 'contact',
  MY: 'my',
};

const Popup = ({visible, onSelect}) => {
  return (<Popover
    overlayClassName="home-popup"
    overlayStyle={{color: 'currentColor'}}
    overlay={[
      (<PopoverItem key="4" value="scan" data-seed="logId">扫码</PopoverItem>),
      (<PopoverItem key="5" value="special">我的二维码</PopoverItem>),
    ]}
    align={{
      overflow: {adjustY: 0, adjustX: 0},
      offset: [-10, 0],
    }}
    onSelect={onSelect}
  >
    <div style={{
      height: '100%',
      padding: '0 15px',
      marginRight: '-15px',
      display: 'flex',
      alignItems: 'center',
    }}>
      <Icon type="plus" size={'sm'}/>
    </div>
  </Popover>);
};

const Home = (props) => {
  //const {userInfo} = useContext(StoreContext);
  let {tab} = props.match.params;

  //console.log('home props', props, userInfo);
  let initialPage;
  switch (tab) {
    case TABS.CONVERSATION:
      initialPage = 0;
      break;
    case TABS.CONTACT:
      initialPage = 1;
      break;
    case TABS.MY:
      initialPage = 2;
      break;
    default:
      initialPage = 0;
      break;
  }

  let commonProps = {
    history: props.history,
  };

  const tabs = [
    {
      key: TABS.CONVERSATION,
      title: '消息',
      icon: 'chat',
      active: initialPage === 0,
      badgeText: props.message.totalUnread,
      content: <Chat {...props}/>,
      //navRight: <Popup/>
    },
    /*{
      key: TABS.CONTACT,
      title: '通讯录',
      icon: 'contact',
      active: initialPage === 1,
      content: <Contact {...commonProps} />
    },*/
    {
      key: TABS.MY,
      title: '我',
      icon: 'my',
      active: initialPage === 2,
      content: <My {...commonProps} />,
    },
  ];

  return (
    <div className="app home">
      <TabBar
        unselectedTintColor={'#949494'}
        tintColor={theme()['@brand-primary']}
        barTintColor={'white'}
        prerenderingSiblingsNumber={0}
      >
        {tabs.map(tab => {
          let {title, key, icon, active, badgeText, badgeDot = false, content, navLeft = null, navRight = null} = tab;
          return (<TabBarItem
            key={key}
            title={title}
            icon={<GIcon className="tab-app" type={icon}/>}
            selectedIcon={<GIcon className="tab-app" type={icon + '-fill'}/>}
            selected={active}
            badge={badgeText}
            dot={badgeDot}
            onPress={() => {
              props.history.push('/' + tab.key);
            }}
          >
            <NavBar
              className="site-nav"
              leftContent={navLeft}
              rightContent={navRight}
            > {title} </NavBar>
            {content}
          </TabBarItem>);
        })}
      </TabBar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.Message,
  };
};
export default connect(mapStateToProps)(Home);
