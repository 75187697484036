//路由跳转动画
import React from 'react'
import {CSSTransition, TransitionGroup,} from 'react-transition-group'
import {Switch, withRouter} from 'react-router-dom';
import './index.scss'

/*const location={
  hash: ""
  key: "76mojc"
  pathname: "/chat"
  search: ""
  state: undefined
}*/

const AnimatedSwitch = props => {
  const {children, location, animation} = props;
  return (
    <TransitionGroup
      className="animate-root"
      component={null}
    >
      <CSSTransition
        key={location.pathname}
        classNames={animation || 'fade'}
        timeout={200} //超时结束动画
        //unmountOnExit={true} //结束后卸载组件
      >
        <Switch location={location}>{children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  )
}

export default withRouter(AnimatedSwitch)
