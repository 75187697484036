/**
 * Created by simon on 2016/12/26.
 * antd lib vars for gst
 */
//定义
//https://github.com/ant-design/ant-design-mobile/blob/master/components/style/themes/default.less

module.exports = () => {
  return {
    // 全局/品牌色
    //'@brand-primary': '#108ee9',
    //'@brand-primary-tap': '#0e80d2',
    //'@brand-success': '#6abf47',
    //'@brand-warning': '#ffc600',
    //'@brand-error': '#f4333c',
    //'@brand-important': '#ff5b05',  // 用于小红点
    //'@brand-wait': '#108ee9',

    '@brand-primary':'#5DCC75',
    '@brand-primary-tap': '#53b369', //#ff9147;
    '@success-color': '#87d068',
    '@error-color ': '#f50',
    '@warning-color ': '#fa0',
    '@font-size-base': '14px',
    // 更改 antd iconfont 成本地
    //'@icon-url': '"/antd-icon/iconfont"',
  };
};
