/**
 * 订单详情reducer
 * */

import {produce} from 'immer';
import {RECEIVE_ORDER_DETAIL} from '../actions/Order';
import {handleAliStringResp} from '../../utils/tools';

const initState = {
  data: {
    order_rsp: {
      medical_info: {}
    }
  },
  isOK: false,
}

export const OrderDetail = produce((state = initState, action) => {
  const {type, payload} = action;
  switch(type){
    case RECEIVE_ORDER_DETAIL:
      console.log('订单详情 reducer', payload)
      if (payload.data.order_rsp && payload.data.order_rsp.medical_info) {
        payload.data.order_rsp.medical_info = handleAliStringResp(payload.data.order_rsp.medical_info); //将阿里返回数据中的string层消除
      }
      return payload;
    default:
      return state;
  }
})
