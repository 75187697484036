// tab 承载容器

import './index.scss';
import React from 'react';

const TabPane = ({wrapperClassName = '', children}) => {

  return (<div className={`body-pane ${wrapperClassName}`}>
    {children}
  </div>);
}
export default TabPane;
