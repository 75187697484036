// 音频组件

import React, {useState, useEffect, useRef} from 'react'
import Recorder from 'recorder-core/recorder.mp3.min';
import './index.scss';

let rec;
/**调用open打开录音请求好录音权限**/
const recOpen=function(success){//一般在显示出录音按钮或相关的录音界面时进行此方法调用，后面用户点击开始录音时就能畅通无阻了
  rec=Recorder({
    type:"mp3",sampleRate:16000,bitRate:16 //mp3格式，指定采样率hz、比特率kbps，其他参数使用默认配置；注意：是数字的参数必须提供数字，不要用字符串；需要使用的type类型，需提前把格式支持文件加载进来，比如使用wav格式需要提前加载wav.js编码引擎
    ,onProcess:function(buffers,powerLevel,bufferDuration,bufferSampleRate,newBufferIdx,asyncEnd){
      //录音实时回调，大约1秒调用12次本回调
      //可利用extensions/waveview.js扩展实时绘制波形
      //可利用extensions/sonic.js扩展实时变速变调，此扩展计算量巨大，onProcess需要返回true开启异步模式
    }
  });

  //var dialog=createDelayDialog(); 我们可以选择性的弹一个对话框：为了防止移动端浏览器存在第三种情况：用户忽略，并且（或者国产系统UC系）浏览器没有任何回调，此处demo省略了弹窗的代码
  rec.open(function(){//打开麦克风授权获得相关资源
    //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
    //rec.start() 此处可以立即开始录音，但不建议这样编写，因为open是一个延迟漫长的操作，通过两次用户操作来分别调用open和start是推荐的最佳流程

    success&&success();
  },function(msg,isUserNotAllow){//用户拒绝未授权或不支持
    //dialog&&dialog.Cancel(); 如果开启了弹框，此处需要取消
    console.log((isUserNotAllow?"UserNotAllow，":"")+"无法录音:"+msg);
  });
};

/**开始录音**/
function recStart(){//打开了录音后才能进行start、stop调用
  rec.start();
}

function blobToDataURL(blob, callback) {
  delete blob.type;
  let a = new FileReader();
  a.onload = function (e) {

    let str = e.target.result;
    if(str) {
      callback(str.slice(str.indexOf('//')))
    }
  }
  a.readAsDataURL(blob);
}

/**结束录音**/
function recStop(cb){
  rec.stop(function(blob,duration){
    console.log(blob,(window.URL||window.webkitURL).createObjectURL(blob),"时长:"+duration+"ms");
    rec.close();//释放录音资源，当然可以不释放，后面可以连续调用start；但不释放时系统或浏览器会一直提示在录音，最佳操作是录完就close掉
    rec=null;
    if(cb) cb(blob,duration);
  },function(msg){
    console.log("录音失败:"+msg);
    rec.close();//可以通过stop方法的第3个参数来自动调用close
    rec=null;
  });
}

const AudioBar = (props) => {
  const audioBar = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [isCancel, setIsCancel] = useState(false);

  useEffect(() => {
    audioBar.current.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    })
  },[])

  const onTouchStart = () => {
    console.log(1);
    setIsActive(true);
    setIsCancel(false);

    recOpen(() => {
      recStart();
    })
  }

  const onTouchMove = (e) => {
    e.preventDefault();
    const x = e.touches[0].pageX,
      y = e.touches[0].pageY;
    const oT = e.target.offsetTop;
    if(oT-y>50) {
      setIsCancel(true);
    }
  }

  const onTouchEnd = () => {
    setIsActive(false);
    if(isCancel) return;

    recStop((blob,duration) => {
      blobToDataURL(blob, (file64) => {
        console.log(file64);
        props.onRecord(file64,duration);
      })
    });
  }

  return (
    <div className="audio-bar">
      <div className={`audio-bar-btn ${isActive&&'active'}`}  ref={audioBar} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <span>长按发送语音</span>
      </div>
    </div>
  )
}

export default AudioBar;

