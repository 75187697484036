import {SHOW_ERROR_INFO} from "../actions/GlobalFeedback";

const initErrorInfo = {
  key           : 1,
  type          : "message",
  title         : "",
  content       : "",
  pre_content   : "",
  interfaceState: {
    status : "",
    message: "",
  }
};

export function GlobalFeedbackInfo(state = initErrorInfo, action) {
  const json = action.json;
  switch (action.type) {
    case SHOW_ERROR_INFO:
      return {...state, ...json};
    default:
      return state;
  }
}
