/**
 * NotFound
 */

import './index.scss';
import React from 'react';
import {Link} from 'react-router-dom';

function NotFound(props){
  console.log('props:', props);
  return (
    <div className="app not-found">
      <p><strong>404</strong></p>
      <p>页面不存在, 请返回首页</p>
      <p>Page not found, please return</p>
      <p><Link to={'/'}>返回首页</Link></p>
    </div>
  );
}

export default NotFound;
