/**
 * 订单
 * */
import {host} from 'src/utils/hostConf';
import {dispatchWithReturn, get} from 'src/utils/baseRequest';

export const RECEIVE_ORDER_DETAIL = 'RECEIVE_ORDER_DETAIL';

//拉取订单详情-患者信息
export const reqOrderDetail = ({session_id}) => (dispatch, getState) => {
  return get({url: `${host.cGo}aliim/handler/QueryAliimOrderDetail`, data: {session_id, info_type: '1'}}).then(json => {
    if (!json.isOK) {
      throw json;
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_ORDER_DETAIL, payload: json});
  });
};
