import './index.scss';
import React from 'react';

function GTag({color, children}) {

  return (<div className="g-tag">
    {children}
  </div>);
}

export default GTag;
