/**
 * 会话/消息列表reducer
 * 引入了immer做不可变对象
 * */
import {produce} from 'immer';
import {
  CLEAN_MESSAGE_LIST, RECEIVE_MESSAGE_LIST, RECEIVE_SESSION_LIST, SEND_MESSAGE, SOCKET_CONNECTED, SOCKET_MESSAGE, ENTER_CONVERSATION,
  LEAVE_CONVERSATION,
} from '../actions/Message';
import {handleAliStringResp} from '../../utils/tools';

const initState = {
  connected: false, //连接状态
  totalUnread: 0, //总未读数
  currentSession: '',//当前会话ID
  sessionList: [], //存储key, session_id
  sessionListObj: {}, //存储内容
  messageListObj: {}, //分session_id存储每个会话的消息列表
};

export const Message = produce((state = initState, action) => {
  const {type, payload} = action;
  switch (type) {
    case SOCKET_CONNECTED:
      state.connected = payload;
      return state;
    case SOCKET_MESSAGE:
      console.log('收到的消息======>', payload);
      //payload.sub_type:  1	[string]	会话,2	[string]	消息
      const {sub_type, data: msgData} = payload;
      switch (sub_type) {
        case 1: //本socket消息是推送了新会话
          state.sessionList.unshift(msgData['session_id']);
          state.sessionListObj[msgData['session_id']] = msgData;
          break;
        case 2: //本socket消息是推送了新消息, //加入到消息头部(最新)
          if (state.messageListObj[msgData.session_id]) {
            //新消息所属的会话打开过,已建立过存储
            state.messageListObj[msgData.session_id].unshift(msgData); //消息列表追加新消息
          } else {
            //新消息所属会话没打开过,这里新建会话的存储
            state.messageListObj[msgData.session_id] = [msgData];
          }
          state.sessionListObj[msgData.session_id].last_msg = msgData.text; //会话列表更改最后一句消息
          state.sessionListObj[msgData.session_id].last_msg_at = msgData.send_time;//会话列表更改最后一句消息时间
          if (!state.currentSession) {
            //不在会话中, 则更新未读数
            state.sessionListObj[msgData.session_id].unchecked += 1;
            state.totalUnread += 1;
          }
          break;
        default:
        // do nothing
      }
      return state;
    case SEND_MESSAGE: //本地发送消息,发送后ws会立即回送该消息,故统一在接收消息处处理
      console.log('发出的消息======>', payload);

      return state;
    case ENTER_CONVERSATION: //进入会话,存储会话ID
      state.currentSession = payload;
      return state;
    case LEAVE_CONVERSATION: //离开会话
      state.currentSession = '';
      return state;
    case RECEIVE_SESSION_LIST:
      const {data} = payload;
      let newList = [];
      state.sessionListObj = {}; //更新会话列表时需要重置原有列表,否则持久化会不更新数据
      state.totalUnread = 0;
      if (data.length) {
        data.forEach(item => {
          if (item.order_rsp) {
            item.order_rsp = JSON.parse(item.order_rsp);
            if (item.order_rsp.medical_info) {
              item.order_rsp.medical_info = handleAliStringResp(item.order_rsp.medical_info); //将阿里返回数据中的string层消除
            }
          }
          newList.push(item['session_id']);
          state.sessionListObj[item['session_id']] = item;
          state.totalUnread += item.unchecked;
        });
      }
      state.sessionList = newList;
      return state;
    case RECEIVE_MESSAGE_LIST:
      if (!payload.data || !payload.data.length) {
        return state; //空消息
      }
      let sid = payload.data[0]['session_id']; //取消息列表的第一个消息中的session_id作为key
      let list = [];
      if (payload.cur_page <= 1) {
        list = payload.data ? payload.data : [];
      } else {
        list = payload.data.concat(state.sessionListObj[sid]); //消息顺序为倒序, 新获取的消息在前面连接
      }
      state.messageListObj[sid] = list;
      return state;
    case CLEAN_MESSAGE_LIST:
      let newMsgObj = {};
      state.sessionList.forEach(sid => {
        newMsgObj[sid] = state.messageListObj[sid];
      });
      state.messageListObj = newMsgObj;
      return state;
    default:
      return state;
  }
});
