/**
 * 会话/消息action
 * */

import {host} from 'src/utils/hostConf';
import {dispatchWithReturn, get, post} from 'src/utils/baseRequest';
import {errorHandlerFactory} from './GlobalFeedback';
import {reqOrderDetail} from './Order';
import {cleanParams} from '../../utils/tools';

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'; //连接情况
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE'; //收到消息
export const SEND_MESSAGE = 'SEND_MESSAGE'; //收到消息
export const RECEIVE_SESSION_LIST = 'RECEIVE_SESSION_LIST';
export const RECEIVE_MESSAGE_LIST = 'RECEIVE_MESSAGE_LIST';
export const CLEAN_MESSAGE_LIST = 'CLEAN_MESSAGE_LIST'; //登录清理不存在的消息列表
export const ENTER_CONVERSATION = 'ENTER_CONVERSATION'; //进入会话
export const LEAVE_CONVERSATION = 'LEAVE_CONVERSATION'; //离开会话

//todo //redux包装器
/*export const reduxWrapper = (action) => (dispatch, getState) => {

}*/

/*export const requestTemplate = (data) => (dispatch, getState) => {
  const url = `${host.oldHost}user_center`;
  return get({url, data}).then(json => {
    return dispatchWithReturn(dispatch, {type: RECEIVE_TEMPLATE, json});
  });
};*/

//设置连接状态
export const setConnection = (status) => (dispatch, getState) => {
  dispatch({type: SOCKET_CONNECTED, payload: status});
};
//接收到WS连接消息
export const receiveWSMsg = (msg) => (dispatch, getState) => {
  const {Message: {currentSession}} = getState();
  dispatch({type: SOCKET_MESSAGE, payload: msg});
  console.log('收到的消息', msg.data.session_id);
  if(currentSession){
    //如果当前在会话中,则发送已读标志
    errorHandlerFactory(reqReadSession({session_id: msg.data.session_id}));
  }
};
//发送消息到本地消息队列
export const sendMsg = (msg) => (dispatch, getState) => {
  dispatch({type: SEND_MESSAGE, payload: msg});
};
//进入某会话
export const enterConversation = (sessionId) => (dispatch, getState) => {
  dispatch({type: ENTER_CONVERSATION, payload: sessionId});
};
//离开某会话回到列表
export const leaveConversation = () => (dispatch, getState) => {
  dispatch({type: LEAVE_CONVERSATION });
};

//登录清理消息列表
export const cleanMessageObj = () => (dispatch, getState) => {
  dispatch({type: CLEAN_MESSAGE_LIST});
};

//拉取历史会话列表
export const reqSessionList = ({doctor_id, asst_id}) => (dispatch, getState) => {
  let params = {
    doctor_id,
    assist_id:asst_id,
    page_size: 999,
  };
  return get({url: `${host.cGo}aliim/handler/queryAliimOrder`, data: cleanParams(params)}).then(json => {
    //return json;
    if (!json.isOK) {
      throw new Error(json);
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_SESSION_LIST, payload: json});
  });
};

//拉取历史消息列表
export const reqMessageList = ({session_id}) => (dispatch, getState) => {
  return get({url: `${host.cGo}aliim/handler/queryAliimSession`, data: {session_id, page_size: 999}}).then(json => {
    //return json;
    if (!json.isOK) {
      throw new Error(json);
    }
    return dispatchWithReturn(dispatch, {type: RECEIVE_MESSAGE_LIST, payload: json});
  });
};

//拉取历史消息并已读当前会话并拉取订单详情
export const getConversationDetail = ({session_id}) => async (dispatch, getState) => {
  let readJson = reqReadSession({session_id});
  let msgJson = dispatch(reqMessageList({session_id}));
  let orderJson = dispatch(reqOrderDetail({session_id}));
  return Promise.all([readJson, msgJson, orderJson]);
};

//发送医生消息给患者
export const reqSendMessage = ({type, session_id, content}) => {
  const keyMap = {
    '1': 'text',
    '2': 'pic',
    '3': 'radio',
  };
  let postData = {
    content_type: type, //类型 1.文本,2.图片,3.音频,31.医嘱小结
    session_id: session_id,
    //pic: "[\\\"\\\"]",
    //radio: "",
    //radio_time: 0,
  };
  if (type === '31') {
    postData = {
      ...postData,
      diagnose: content[0],
      advice: content[1],
    };
  } else if (type === '3') {
    postData = {
      ...postData,
      radio: content[0],
      radio_time: Math.round(content[1] / 1000), //毫秒转秒
    };
  } else {
    postData = {
      ...postData,
      [keyMap[type]]: content,
    };
  }
  return post({url: `${host.cGo}aliim/handler/sessionDoctorSend`, data: postData}).then(json => {
    return json;
  });
};

//确认消息已读 某会话已读
export const reqReadSession = ({session_id}) => {
  return post({url: `${host.cGo}aliim/handler/confirmRead`, data: {session_id}}).then(json => {
    return json;
  });
};

// 上传图片 语音
export const uploadImData = ({doctor_id, content_type, file}) => {
  return post({url: `${host.cGo}aliim/handler/uploadImData`, data: {doctor_id, content_type, file}});
};

//推送消息接口 websocket
export const messageWSURI = `${host.cWS}websocket/hi?`;

//医生拒诊
export const reqRefuseOrder = ({order_id, reason = ''}) => {
  return post({url: `${host.cGo}aliim/handler/orderRefuse`, data: {order_id, reason}}).then(json => {
    return json;
  });
};
