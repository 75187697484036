/**
 * 基础请求拼装
 * @param url:[string] 请求地址
 * @param headers:[func] 特殊请求头, 都可选
 */
import axios from 'axios';

const defaultOptions = {
  credentials: 'include',
  timeout: 10000,
  headers: {
    'content-type': 'application/json',
  },
};

const serverLog = ({url, postData, respData, sendTime}) => {
};

const request = (url, fetchParams) => {
  return axios({url, ...fetchParams});
};
export const noop = () => {
};
export const dispatchWithReturn = (dispatch, action) => {
  dispatch(action);
  return action.payload;
};

export const get = ({url = '', data = null, option}) => {
  const sendTime = Date.now();
  return request(url, {method: 'get', params: data, ...defaultOptions, ...option})
    .then(res => {
      return res.data;
    })
    .then(json => {
      json.isOK = (+json.status === 0 || +json.status === 8193 || json.code === 200);
      json.original = {url, data};
      return json;
    })
    .catch(e => {
      serverLog({url, postData: data, respData: e, sendTime});
      if (e.response) {
        throw e.response.data;
      } else {
        throw e;
      }
    });
};

export function post({url = '', data = null, option}){
  const sendTime = Date.now();
  return request(url, {method: 'post', data, ...defaultOptions, ...option})
    .then(res => {
      // console.info('------------------',res)
      return res.data;
    })
    .then(json => {
      json.isOK = (+json.status === 0 || +json.status === 8193 || json.code === 200);
      json.original = {url, data};
      return json;
    })
    .catch(e => {
      serverLog({url, postData: data, respData: e, sendTime});
      if (e.response) {
        throw e.response.data;
      } else {
        throw e;
      }
    });
}

