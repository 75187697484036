// 会话列表中的会话项目

import './index.scss';
import React from 'react';
import {Link} from 'react-router-dom';
import {Badge,Tag} from 'antd-mobile';
import GTag from '../GTag';
import dayjs from 'dayjs';
import {SESSION_STATUS} from 'src/utils/public';
import avatarImg from 'src/assets/img/avatar.png';

const BadgeIcon = ({badgeText = 0, badgeDot = false, children, ...rest}) => {
  return badgeDot ? <Badge dot {...rest}>{children}</Badge> :
    <Badge text={badgeText} {...rest}>{children}</Badge>
}

const ChatItem = (props) => {
  /*const json = {
    ali_doctor_uuid: "098f8b3e1effc626809427ffc79f968f1599639714934",
    ali_patient_uuid: "",
    created_at: "1600236754",
    deleted_at: "0",
    dialog_close_reason: "",
    doctor_id: "340",
    doctor_name: "",
    last_msg: "",
    last_msg_at: "0",
    order_id: "2009160037960117",
    order_status: "DIAGNOSED",
    patient_name: "",
    session_id: "5b169cfa8065247408da3709249cd9ac",
    source: "腾讯医疗",
    unchecked: 0,
    updated_at: "1600249762",
  }*/
  const {headerImg, doctor_name, source, patient_name, session_id, last_msg, last_msg_at, unchecked, order_status} = props.data;

  return (<Link to={`/chat/${session_id}`} className="component session-item">
    <div className="session-item-avatar">
      <BadgeIcon
        badgeText={unchecked}
        overflowCount={99}
      >
        <img src={headerImg || avatarImg} alt=""/>
      </BadgeIcon>
    </div>
    <div className="session-item-content">
      <div className="sic-time">{+last_msg_at ? dayjs.unix(+last_msg_at).format('MM-DD HH:mm') : ''}</div>
      <div className="sic-doctor"><strong>{doctor_name}</strong> <Tag small>{source}</Tag></div>
      <div className="sic-author">{patient_name || session_id}</div>
      <div className="sic-status"><Tag small>{SESSION_STATUS[order_status]}</Tag></div>
      <div className="sic-msg">{last_msg}</div>
    </div>
  </Link>);
}
export default ChatItem;
