//入口文件
import "./assets/index.scss";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {renderRoutes} from "react-router-config";
import {Provider} from 'react-redux';
import {persistor, store} from './store/configureStore';
import {PersistGate} from 'redux-persist/integration/react';
import routes from "./routes";
import * as serviceWorker from "./serviceWorker";
import promiseFinally from 'promise.prototype.finally';
promiseFinally.shim();//promise finally 对低版本浏览器的兼容

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {renderRoutes(routes)}
      </PersistGate>
    </Provider>
  </BrowserRouter>
  , document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
