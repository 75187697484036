/**
 * 会话列表置顶通知
 */

import './index.scss';
import React from 'react';

const ChatTop = (props) => {
  const {children} = props;
  return (
    <div className="component chat-top">
      {children}
    </div>
  );
}

export default ChatTop;
