/**
 * @author simon
 * @Description: Root容器, 网站共用的可以放这里
 */

import './index.scss';
import React, {Component} from 'react';
import {renderRoutes} from 'react-router-config';
import queryString from 'query-string';
import LoginBox from 'src/components/LoginBox';
import {StoreProvider} from 'src/store/context';
import {getUser} from 'src/utils/user';
import {cleanMessageObj, messageWSURI, receiveWSMsg, setConnection} from 'src/store/actions/Message';
import {connect} from 'react-redux';
import AnimatedSwitch from '../AnimatedSwitch';
import {parseParams} from '../../utils/tools';

const maxErrorTime = 10; //最多重试次数
let wsConnectErr = 0; //连接ws错误次数,

class Application extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginState: 'checking',
      userInfo: getUser(),
    };
    const queryTicket = queryString.parse(props.location.search).ticket;
    this.static = {
      ticket: Array.isArray(queryTicket) ? queryTicket[queryTicket.length - 1] : queryTicket, //如果ticket有多个,则变成数组,取最后一个
      socket: null,
    };
  }

  /*
    props: {
      history: ...,
      location: ...,
      match: ...,
      route: ...,
      staticContext: ...,
      其他自定義傳入的屬性: ...
    }
  */

  componentDidMount() {
    console.log('APP-用户信息', this.state.userInfo);
    const {userInfo} = this.state;
    let isLogin = userInfo && userInfo.system_uid;
    this.setState({loginState: isLogin ? 'signed' : 'noSigned'});
    if (isLogin) {
      console.log('已登录, 跑socket连接==========>');
      this.connectToWS();
      this.props.dispatch(cleanMessageObj()); //登录清理消息列表
    }
  }

  renderSignedPage() {
    const {route, history, location} = this.props;
    const {userInfo} = this.state;
    return (
      <StoreProvider value={{location, history, userInfo}}>
        <AnimatedSwitch animation={'fade'}>
          {renderRoutes(route.routes)}
        </AnimatedSwitch>
      </StoreProvider>
    );
  }

  //连接服务器ws
  connectToWS() {
    this.connectSocket(this.static.socket);
  }

  connectSocket(socket) {
    if (wsConnectErr >= maxErrorTime) {
      throw new Error('websocket连接已达最大重试次数,请检查连接地址');
    }
    const {doctor_id, asst_id} = this.props.userInfo;
    let params = parseParams({
      type: doctor_id ? '2' : '3', //医生2,医助3
      id: doctor_id || asst_id,
    });
    socket = new WebSocket(messageWSURI + params);
    socket.onopen = () => {
      console.info(`[+] 连接成功`);
      //Toast.success('服务器连接成功');
      this.props.dispatch(setConnection(true));
    };
    socket.onerror = (error) => {
      //Toast.fail('连接错误');
      console.info(`[-] 连接错误`, error);
      wsConnectErr += 1;
      this.props.dispatch(setConnection(false));
    };
    socket.onclose = () => {
      console.info(`[-] 连接关闭`);
      this.connectSocket(); //自动重新连接
    };
    socket.onmessage = (msg) => {
      let data = JSON.parse(msg.data);
      console.info(`[*] 收到消息`, data);
      let msgData = {
        time: data.time,
        ...data.list[0],
      };
      this.props.dispatch(receiveWSMsg(msgData));
    };
  }

  onSigned(singedInfo) {
    this.setState({
      loginState: 'signed',
      userInfo: singedInfo.userInfo,
    });
    //连接服务器
    this.connectToWS();
  }

  render() {
    const {loginState} = this.state;
    const {ticket} = this.static;

    switch (
      loginState // checking:检查登录 | signed:已登录 | noSigned: 未登录
      ) {
      case 'noSigned':
        return (
          <div className="app login">
            <LoginBox ticket={ticket} onSigned={this.onSigned.bind(this)}/>
          </div>
        );
      case 'signed':
        return this.renderSignedPage();
      case 'checking':
      default:
        return (
          <div className="app login">
            <div className="component login-box"> 请稍候...</div>
          </div>
        );
    }
  }
}

//export default Application;
const mapStateToProps = (state) => {
  return {
    message: state.Message,
    userInfo: state.UserInfo,
  };
};
export default connect(mapStateToProps)(Application);

