/**
 * @author simon
 * @date 2020/4/21 4:55 下午
 * @Description: 空列表
 */

import './index.scss';
import React from 'react';

function EmptyList({text = '暂无记录'}) {
  return (
      <div className="empty-list">{text}</div>
  );
}

export default EmptyList;
