import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import rootReducer from './reducers';

const isBrowser = !!(typeof document !== 'undefined' && window);
const composeEnhancers = isBrowser ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;//DEV_TOOLS支持
// redux-persist redux持久化配置
const persistConfig = {
  key: 'root',
  storage, // 定义使用哪种持久化存储
  whitelist: ['Message'] // 持久化白名单
}
const persistedReducer = persistReducer(persistConfig, rootReducer) // 创建持久化reducer

//定义中间件,加入异步中间件thunkMiddleware //在非上线环境不加入DEV_TOOL
const createStoreWithMiddleware = (process.env.NODE_ENV === 'production')
  ? applyMiddleware(thunkMiddleware)
  : composeEnhancers(applyMiddleware(thunkMiddleware));

const store = createStore(
  persistedReducer, // pass the persisted reducer instead of rootReducer to createStore
  createStoreWithMiddleware // add any middlewares here
)

const persistor = persistStore(store); // used to create the persisted store, persistor will be used in the next step

export {store, persistor}
