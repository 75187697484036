/**
 * 类组件template
 */

import React from 'react';
import StoreContext from '../store/context';

class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    console.log(111111, this.context);
  }

  render() {
    return (
        <div>
          test
        </div>
    );
  }

  static contextType = StoreContext;
}

export default Test;
