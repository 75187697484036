import React from 'react';
import './audio-play-icon.scss';

//reverse 喇叭方向,默认在从左向右, reverse=true 则从右向左
const AudioPlayIcon = ({playing, reverse, className=''}) => {
  return (
      <div className={`voice ${playing?'voicePlay':''} ${reverse?'reverse':''} ${className}`}> </div>
  )
}

export default AudioPlayIcon;
