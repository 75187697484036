/**
 * GST Icon
 */

import React from 'react';

function GIcon({type = '', reverse, children, className = '', ...rest}) {
  return (
      <span className={'iconfont ' + (type ? `iconfont-${type} ` : '') + (reverse?'reverse ':'') + className} {...rest}>{children}</span>
  );
}

export default GIcon;
