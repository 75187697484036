/**
 * 会话记录
 */
import './index.scss'
import React, {useRef, useState} from 'react';
import Zmage from 'react-zmage'
import GIcon from '../GIcon.jsx';
import AudioPlayIcon from './AudioPlayIcon';

const TextMessage = ({text}) => {
  return <div className="msg-content msg-text">{text}</div>
}
const PicMessage = ({picList}) => {
  const onImgErr = (e) => {
    e.target.src = `/assets/img-error.jpg`; //指向 public/assets/img-error.jpg,是从public目录直接拷贝过去的
  }
  return <div className="msg-content msg-pic">
    {picList.map((img, idx) => {
      return <Zmage preset={"mobile"} key={idx} src={img} alt="" onError={onImgErr}/>
    })}
  </div>
}
const AudioMessage = ({audio, audioTime, isSender}) => {
  const audioDom = useRef(null);
  const [playing, setPlaying] = useState(false);
  const [loaded, setLoaded] = useState(true);

  const playAndPauseAudio = () => {
    if (audioDom.current.paused) {
      setPlaying(true);
      audioDom.current.play();
    } else {
      setPlaying(false);
      audioDom.current.currentTime = 0;
      audioDom.current.pause();
    }
  }
  const onAudioError = (e) => {
    setLoaded(false);
  }
  const onAudioEnd = (e) => {
    setPlaying(false);
  }
  const calcWidth = ()=>{
    if(audioTime * 10 > 32){ //最小宽度32. 1秒钟10像素长度
      return {width: audioTime * 10 + 'px'}
    }else{
      return {}
    }
  }

  return <div className={`msg-content msg-audio ${loaded ? '' : 'error'}`}>
    <audio src={audio} ref={audioDom} onError={onAudioError} onEnded={onAudioEnd}/>
    {loaded ?
      (<div className={`msg-audio-box ${isSender ? 'reverse' : ''}`}
            style={calcWidth()}
            onClick={playAndPauseAudio}>
        {isSender ?
          <React.Fragment><span>{audioTime}"</span><AudioPlayIcon playing={playing} reverse={true}/></React.Fragment> :
          <React.Fragment><AudioPlayIcon playing={playing}/><span>{audioTime}"</span></React.Fragment>
        }
      </div>)
      : <div className="msg-audio-box"><GIcon type="sounding"/> 该音频已过期 </div>
    }
  </div>
}

const DiagnoseMessage = ({diagnose, advice}) => {
  return (
    <div className={`msg-content msg-diagnose`}>
      <h4>医师诊断：</h4>
      <p>{diagnose}</p>
      <div className="line"> </div>
      <h4>医师建议：</h4>
      <p>{advice}</p>
    </div>
  )
}

const MessageContent = ({contentData}) => {
  const {content_type, text, pic, radio, radio_time, advice, diagnose, owner} = contentData;
  switch(content_type.toString()){
    case '2': //[string]	图片
      return <PicMessage picList={pic}/>
    case '3': //[string]	音频
      return <AudioMessage audio={radio} audioTime={radio_time} isSender={owner === 1}/>
    case '31': //[string]	医嘱
      return <DiagnoseMessage diagnose={diagnose} advice={advice}/>
    case '1':	//[string]	文本
    default:
      return <TextMessage text={text}/>
  }
}

const MessageItem = ({data}) => {
  const isSender = data.owner === 1; //1.医生;2.患者
  const nodeArrow = <div className="arrow"/>
  const nodeContent = <MessageContent contentData={data}/>
  const nodeThumb = <GIcon type="my-fill" className="thumb"/>

  return isSender ? <div className="message-line item-right">{nodeContent}{nodeArrow}{nodeThumb}</div> :
    <div className="message-line item-left">{nodeThumb}{nodeArrow}{nodeContent}</div>
}
export default MessageItem;
