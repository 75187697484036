/**
 * Host Config
 * @type {string}
 */

export const staticHost = '';
const _base = '//admin-gstzy.oss-cn-shenzhen.aliyuncs.com';
const _picHost = '//www.gstzy.cn';

let __host = {
  php: `${staticHost}/api/`,
  dr_img: `${_base}/data/upload/`, //图片服务器
  picHost: `${_picHost}`, // 图片服务器地址(运营后台)
  defAvatar: `${_base}/data/upload/doctor/201603/25/56f4e1fc9e01c.png`,
  defaultDocImg: '/assets/img/i-doctor-head-default.png',
  go: `${staticHost}/api/stg-go-api.gstyun.cn/`,
};
console.log('process.env.NODE_ENV', process.env.NODE_ENV);

switch (process.env.NODE_ENV) {
  // case 'production':
  // case 'development':
  case 'dev':
    __host = Object.assign({}, __host, {
      dr_img: `//admin.gstyun.local/data/upload/`,
      cHost: `${staticHost}/apic/beta-cgi.gstyun.cn/`,
      cGo: `${staticHost}/apic/dev-go-api.gstyun.cn/`,
      cWS: `wss://dev-go-api.gstyun.cn/`,
      data: `//data-api.gstzy.cn:63306/`,
    });
    break;
  // case 'development':
  case 'test':
    __host = Object.assign({}, __host, {
      dr_img: `//admin.gstyun.local/data/upload/`,
      cHost: `${staticHost}/apic/beta-cgi.gstyun.cn/`,
      cGo: `${staticHost}/apic/go-api.gstyun.cn/`,
      cWS: `wss://go-api.gstyun.cn:40002/`,
      data: `//data-api.gstzy.cn:63306/`,
    });
    break;
  case 'development':
  // case 'production':
  // eslint-disable-next-line no-fallthrough
  case 'stg':
    __host = Object.assign({}, __host, {
      dr_img: `//admin.gstyun.cn/data/upload/`,
      cHost: `${staticHost}/apic/stg-cgi.gstyun.cn/`,
      cGo: `${staticHost}/apic/stg-go-api.gstyun.cn/`,
      cWS: `wss://stg-go-api.gstyun.cn/`,
      data: `//data-api.gstzy.cn/`,
    });
    break;
  case 'production':
  default:   // 生产环境
    __host = Object.assign({}, __host, {
      dr_img: `//admin.gstyun.cn/data/upload/`,
      cHost: `${staticHost}/apic/cgi.gstyun.cn/`,
      cGo: `${staticHost}/api/stg-go-api.gstyun.cn/`,
      cWS: `wss://stg-go-api.gstyun.cn/`,
      data: `//data-api.gstzy.cn/`,
    });
}

export const host = __host;

// 固生堂岭南中医馆东山门诊部 265
// 固生堂岭南中医馆淘金门诊部 267
// 固生堂岭南中医馆          264
// 固生堂岭南中医馆水荫门诊部 266
// 广州天河固生堂医疗门诊部  251
// 广州海珠固生堂中医门诊部  311
// 固生堂中医肿瘤专科门诊部  406
