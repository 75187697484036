/**
 * 会话内容
 */

import './index.scss';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Toast} from 'antd-mobile';
import {Prompt} from 'react-router-dom';
import Page, {NavBack, NavHome} from 'src/components/Page/index';
import GIcon from 'src/components/GIcon.jsx';
import MessageItem from '../../components/Chat/MessageItem';
import ChatBar from '../../components/ChatBar/index';
import OrderDetail from '../../components/OrderDetail/index';
import {enterConversation, getConversationDetail, leaveConversation, reqRefuseOrder, reqSendMessage} from 'src/store/actions/Message';

// import {useRemoveIOSScroll} from '../../utils/hooks';

function Conversation(props) {
  //useRemoveIOSScroll(); //移除IOS外框弹性
  const [profileShow, setProfileShow] = useState(false);
  const {id: session_id} = props.match.params;
  const {messageList, dispatch, orderDetail, userInfo: {doctor_id}} = props;

  useEffect(() => {
    Toast.loading('加载中...', 0);
    dispatch(enterConversation(session_id));
    dispatch(getConversationDetail({session_id})).then(res => {
      console.log('会话详情初始化:', res);
    }).catch(err => {
      Toast.fail(err.detail || err.message);
    }).finally(() => {
      Toast.hide();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session_id, dispatch]);

  const onSendMsg = async (msg, type = '1') => {
    console.log('要发送的信息', msg);
    let res = {};
    try {
      Toast.loading('加载中...', 0);
      res = await reqSendMessage({
        session_id,
        type,
        content: msg,
      });
      Toast.hide();
    } catch (e) {
      console.log(e);
      Toast.fail(e.detail);
    }

    if (res.isOK) {
      //更新历史消息
      //socket onmessage自动加入,故不再重新拉历史消息接口
      //getMessageList(session_id)
      //todo 更新滚动条位置
    }
  };
  //拒诊
  const refuseOrder = (reason) => {
    console.log('点击拒诊', reason);
    let order = props.message.sessionListObj[session_id];
    reqRefuseOrder({order_id: order.order_id, reason}).then(json => {
      setProfileShow(false);
    }).catch(e => {
      Toast.fail(e.detail);
    });
  };

  //离开页面
  const handlePrompt = (location) => {
    console.log('离开页面了')
    dispatch(leaveConversation());
    return true;
  };

  const title = <span className="conversation-title">{orderDetail.data.patient_name || session_id}</span>;
  const navRight = <GIcon type="profile" className="patient-profile" onClick={() => {
    setProfileShow(true);
  }}/>;
  return (
    <Page
      wrapperClassName="conversation"
      pageTitle={title}
      navLeft={[<NavBack key="back"/>, <NavHome key="home"/>]}
      navRight={navRight}
    >
      <div className="conversation-content">
        {messageList.length ? messageList.map(msg => {
          return (<MessageItem key={msg.record_id} data={msg}/>);
        }) : null}
      </div>
      <ChatBar
        className="foot"
        onSend={onSendMsg}
        doctorId={doctor_id}
      />
      <OrderDetail order={props.message.sessionListObj[session_id]}
                   visible={profileShow}
                   onClose={() => {
                     setProfileShow(false);
                   }}
                   onRefuse={refuseOrder}
      />
      <Prompt message={handlePrompt}/>
    </Page>
  );
}

const mapStateToProps = (state, ownProps) => {
  const {id} = ownProps.match.params;
  const list = state.Message.messageListObj[id]; //取相应id的message列表
  return {
    userInfo: state.UserInfo,
    message: state.Message,
    messageList: list ? list : [],
    orderDetail: state.OrderDetail,
  };
};
export default connect(mapStateToProps)(Conversation);

