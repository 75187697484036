/**
 * 医生信息/账户相关
 * */

import {produce} from 'immer';
import {CLEAN_USER_INFO, RECEIVE_DOCTOR_DETAIL, RECEIVE_ASSISTANT_DETAIL, RECEIVE_DOCTOR_ONLINE_STATUS, SET_USER_INFO} from '../actions/Account';
import {getUser} from 'src/utils/user.js';

//登录用户信息,包含医生信息
//const reducer = produce((draftState, action) => {}) //immer produce 使用
export const UserInfo = produce((state = getUser(), action) => {
  const {type, payload} = action;
  switch (type) {
    case SET_USER_INFO:
      return {
        ...state,
        ...payload,
      };
    case CLEAN_USER_INFO:
      return {};
    case RECEIVE_DOCTOR_DETAIL:
      state.ali = payload.data;
      return state;
    case RECEIVE_DOCTOR_ONLINE_STATUS:
      // console.log(11111, payload);
      if (payload.isOK) {
        state.ali.status = payload.original.data.status;
      }
      return state;
    case RECEIVE_ASSISTANT_DETAIL:
      console.log(11111, payload);
      state.asst = payload.data;
      return state;
    default:
      return state;
  }
});
